import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Container = styled.div`
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;

	a {
		text-decoration: underline;
		color: #fff;

		:hover {
			text-decoration: none;
		}
	}
`;

const Purposes = styled.table`
	width: 100%;
	max-width: 794px;

	th {
		font-weight: 600;
		text-align: left;
	}

	td:nth-child(1) {
		width: 35%;
	}

	td:nth-child(2) {
		width: 33%;
	}

	td:nth-child(3) {
		width: 32%;
	}
`;

const Cookies = styled.table`
	width: 100%;
	max-width: 683px;

	th {
		font-weight: 600;
		text-align: left;
	}

	td:first-child {
		width: 40%;
		word-break: break-all;
	}
`;

const Privacy = () => (
	<Layout>
		<SEO title="Privacy Policy" />

		<Container>
			<h1>Privacy Policy</h1>

			<p>This is privacy notice of Digital Arm Ltd.</p>

			<p>We respect your privacy and are determined to protect your personal data. The purpose of this privacy notice is to inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from). We’ll also tell you about your privacy rights and how the data protection law protects you.</p>

			<h2>Who we are and important information</h2>
			<p><strong>What is the purpose of this privacy notice?</strong> <br></br>This privacy notice aims to give you information on how we collect and process your personal data through your use of this website, including any data you may provide through this website when you submit an enquiry / contact form.</p>
			<p>This website is not intended for children and we do not knowingly collect data relating to children.</p>
			<p>You must read this privacy notice together with any other privacy notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice supplements the other notices and is not intended to override them.</p>
			<p><strong>Data controller(s)</strong> <br></br>Digital Arm Ltd is the controller and responsible for your personal data (collectively referred to as  "we", "us" or "our" in this privacy notice). For all data matters contact us on <a href="mailto:support@digitalarm.co.uk">support@digitalarm.co.uk</a></p>
			<p><strong>Third-party links outside of our control</strong> <br></br>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements.</p>
			<p>When you leave our website, we encourage you to read the privacy notice of every website you visit.</p>

			<h2>The personal data we collect about you</h2>
			<p>Personal data, or personal information, means any information about an individual from which that person can be identified. You can find out more about personal data from the <a href="https://ico.org.uk/global/contact-us/">Information Commissioners Office</a>.</p>
			<p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:</p>

			<ul>
				<li><strong>Identity Data</strong> may include [first name/last name/ username/marital status/ title/date of birth/gender].</li>
				<li><strong>Contact Data</strong> may include [billing address/delivery address/email address/ telephone numbers].</li>
			</ul>

			<p>We also collect, use and share <strong>Aggregated Data</strong> such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.</p>
			<p>We do not collect any <strong>Special Categories of Personal Data</strong> about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>

			<h2>How we collect your personal data</h2>
			<p>You may give us your Identity and Contact Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</p>

			<ul>
				<li>Apply for our products or services;</li>
				<li>Create an account on our website;</li>
				<li>Subscribe to our service or publications;</li>
				<li>Submit a web form;</li>
				<li>Request marketing to be sent to you.</li>
			</ul>

			<h2>How we use your personal data</h2>
			<p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>

			<ul>
				<li><strong>Performance of Contract</strong> this means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</li>
				<li><strong>Legitimate Interest</strong> this means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting <a href="mailto:support@digitalarm.co.uk">support@digitalarm.co.uk</a>.</li>
				<li><strong>Comply with a legal or regulatory obligation</strong> this means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</li>
			</ul>

			<p>Generally we do not rely on consent as a legal basis for processing your personal data other than in relation to sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting  <a href="mailto:support@digitalarm.co.uk">support@digitalarm.co.uk</a>.</p>

			<h2>Purposes for which we will use your personal data</h2>
			<p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, with the legal bases we rely on to do so.</p>

			<Purposes>
				<tbody>
					<tr>
						<th>Purpose / Activity</th>
						<th>Type of data</th>
						<th>Lawful basis for processing including basis of legitimate interest</th>
					</tr>
					<tr>
						<td>To register you as a new customer or retain contact</td>
						<td >Identity &amp; Contact</td>
						<td>Performance of a contract with you</td>
					</tr>
				</tbody>
			</Purposes>

			<p><strong>Marketing</strong> <br></br>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.</p>

			<p>We have established the following personal data control mechanisms:</p>

			<ul>
				<li><strong>Promotional offers from us</strong>
						You may receive marketing communications from us if you have requested information from us or purchased goods or services from us and, in each case, you have opted in to receiving that marketing.</li>
				<li><strong>Opting out</strong>
						You can ask us to stop sending you marketing messages at any time by contacting <a href="mailto:support@digitalarm.co.uk">support@digitalarm.co.uk</a>. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a service experience or other transaction.</li>
			</ul>

			<p><strong>Cookies</strong> <br></br>A cookie is a text file placed onto your device when you access websites. We use cookies and other online tracking devices such as web beacons, and flash object storage to deliver, improve and monitor our website, including in the following ways:</p>

			<Cookies>
				<tbody>
					<tr>
						<th>Cookie</th>
						<th>Purpose</th>
					</tr>
					<tr>
						<td>wp-settings</td>
						<td>Used to identify site settings</td>
					</tr>
					<tr>
						<td>wp-settings-time</td>
						<td>Used to identify site settings</td>
					</tr>
				</tbody>
			</Cookies>

			<p>The information we obtain from our use of cookies will not usually contain your personal data. Although we may obtain information about your device such as your IP address, your browser and/or other internet log information, this will not usually identify you personally.</p>
			<p>Our website displays a notice alerting you to our use of cookies and other similar technologies and linking to this privacy policy. If you use our websites after this notice has been displayed to you we will assume that you consent to our use of cookies or similar technologies for the purposes described in this privacy policy.</p>
			<p>Please note that, if you choose to disable cookies or similar technologies on your device, you may be unable to make full use of our Service.</p>
			<p>We work with third parties who may also set cookies on our website, for example Google Analytics, which we use to monitor how visitors use our Service. These third party suppliers are responsible for the cookies they set on your device. For further information please visit the website of the relevant third party.</p>
			<p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.</p>
			<p><strong>Change of purpose</strong> <br></br>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
			<p>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>

			<h2>Third Parties</h2>
			<p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>

			<h2>Data Security</h2>
			<p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
			<p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

			<h2>Data Retention</h2>
			<p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
			<p>In some circumstances you can ask us to delete your data: see <strong>Your legal rights</strong> below for further information.</p>
			<p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>

			<h2>Your legal rights</h2>
			<p>Unless subject to an exemption under the data protection laws, you have the following rights with respect to your personal data:</p>

			<ul>
				<li>The right to request a copy of the personal data which we hold about you;</li>
				<li>The right to request that we correct any personal data if it is found to be inaccurate or out of date;</li>
				<li>The right to request your personal data is erased where it is no longer necessary to retain such data;</li>
				<li>The right to withdraw your consent to the processing at any time, where consent was the lawful basis for processing your data;</li>
				<li>The right to request that we provide you with your personal data</li>
				<li>The right, where there is a dispute in relation to the accuracy or processing of your personal data, to request a restriction is placed on further processing;</li>
				<li>The right to object to our processing of personal data, where applicable i.e. where processing is based on our legitimate interests (or in performance of a task in the public interest/exercise of official authority); direct marketing or processing for the purposes of scientific/historical research and statistics).</li>
			</ul>

			<p>If you wish to exercise any of the rights set out above, please contact <a href="mailto:support@digitalarm.co.uk">support@digitalarm.co.uk</a>.</p>
			<p><strong>No fee required – with some exceptions</strong> <br></br>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable admin fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
			<p><strong>What we may need from you</strong> <br></br>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
			<p><strong>Time limit to respond</strong> <br></br>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>

			<h2>Changes to this notice and your duty to inform us of changes in personal data</h2>
			<p>This version was last updated on 10/05/2018.</p>
			<p>Please keep us informed if your personal data changes during your relationship with us. It is important that the personal data we hold about you is accurate and current.</p>

			<h2>Queries, requests or concerns</h2>
			<p>To exercise all relevant rights, queries or complaints in relation to this policy or any other data protection matter between you and us, please in the first instance contact <a href="mailto:support@digitalarm.co.uk">support@digitalarm.co.uk</a>.</p>
			<p>If this does not resolve your complaint to your satisfaction, you have the right to lodge a complaint with the <a href="https://ico.org.uk/global/contact-us/">Information Commissioners Office</a> on 03031231113 or via email <a href="https://ico.org.uk/global/contact-us/email/">https://ico.org.uk/global/contact-us/email/</a> or at the Information Commissioner's Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF, England, UK.</p>
		</Container>
	</Layout>
);

export default Privacy;
